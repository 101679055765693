import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const formSent = () => (
  <Layout>
    <SEO title="Contact" />
    <div id="contact">
      <h1>the form</h1>
      <p>
        Like the site? Need a site? Found a typo? Need a friend? Whatever the
        reason, feel free to reach out! I'll see what I can do.
      </p>

      <hr />

      <h2>Form Submitted!</h2>
      <p>
        Thanks for reaching out! I appreciate you.
      </p>
      <ul>
        <li>
          {' '}
          <Link to="/">Return Home</Link>
        </li>
        <li>
          {' '}
          <Link to="/blog">View Blog</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default formSent
